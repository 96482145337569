<template>
  <div>
    <b-row align-v="center" :class="{ 'mt-5 p-2' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}" align="center">
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <title-page title="CONTATO" class="titulo"/>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="4" xl="4">
        <b-img src="img/ICONE_CONTATO.png" fluid :class="{ 'mt-5 mb-3' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}" v-if="!imgWebp"/>
        <b-img src="img/ICONE_CONTATO.webp" fluid :class="{ 'mt-5 mb-3' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}" v-if="imgWebp"/>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="6" xl="6 ">
        <b-row class="formulario" :class="{ 'p-2' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}">
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <label>NOME</label>
            <input type="text" v-model.trim="form.nome" @keyup.enter="Send" :disabled="loadingSend">
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <label>TELEFONE</label>
            <input type="tel" v-model.trim="form.telefone" v-mask="['(##) ####-####', '(##) #####-####']" :disabled="loadingSend" @keyup.enter="Send">
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <label>E-MAIL</label>
            <input type="email" v-model.trim="form.email" :disabled="loadingSend" @keyup.enter="Send">
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <label>ASSUNTO</label>
            <input type="text" v-model.trim="form.assunto" :disabled="loadingSend" @keyup.enter="Send">
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <label>MENSAGEM</label>
            <textarea v-model.trim="form.mensagem" :disabled="loadingSend"></textarea>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="4" xl="4" class="mt-2">
            <button class="enviar" @click="Send" :disabled="loadingSend" :class="{ 'disabled' : loadingSend }">{{ loadingSend? 'AGUARDE ...' : 'ENVIAR' }}</button>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="4" xl="4" class="mt-2">
            <button class="limpar" @click="ClearFields" v-if="!loadingSend">LIMPAR</button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import TitlePage from '../components/TitlePage.vue'
export default {
  name: 'contato',
  components:{
      TitlePage
  },
  computed:{
    imgWebp(){
      return this.canUseWebP()
    }
  },
  data(){
    return {
      form:{
        nome: null,
        telefone: null,
        email: null,
        assunto: null,
        mensagem: null,
      },
      loadingSend: false,
      Toast: this.$swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })
    }
  },
  methods:{
    canUseWebP() {
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      return false;
    },
    Send(){
      if(!this.loadingSend){
        let form = this.form;
        let emailCheck = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i;
        if(!form.nome || form.nome && form.nome.length <= 0) this.ToastError('Preencha o campo Nome')
        else if(!form.telefone || form.telefone && form.telefone.length <= 0) this.ToastError('Preencha o campo Telefone')
        else if(form.telefone && form.telefone.length < 14 || form.telefone && form.telefone.length > 15 || parseInt(form.telefone[5]) === 9 && form.telefone.length !== 15) this.ToastError('Telefone inválido')
        else if(!form.email || form.email && form.email.length <= 0) this.ToastError('Preencha o campo E-mail')
        else if(!emailCheck.test(form.email)) this.ToastError('E-mail inválido')
        else if(!form.assunto || form.assunto && form.assunto.length <= 0) this.ToastError('Preencha o campo Assunto')
        else if(!form.mensagem || form.mensagem && form.mensagem.length <= 0) this.ToastError('Preencha o campo Mensagem')
        else {
          this.loadingSend = true;
          this.axios
          .post("https://andlima.com.br/hl/api?acao=contato", this.form)
          .then((response) => {
            this.loadingSend = false;
            if(response.data.acao === 'ERRO'){
              this.ToastError(response.data.mensagem)
            } else {
              this.MessageSuccess()
              this.ClearFields();
            }
          })
          .catch((error) => {
            this.loadingSend = false;
            this.ToastError('Erro ao enviar tente novamente mais tarde.')
          });
        }
      }
    },
    ClearFields(){
      this.form = {
        nome: null,
        telefone: null,
        email: null,
        assunto: null,
        mensagem: null,
      };
    },
    ToastError(msg) {
      this.Toast.fire({
        icon: 'error',
        title: msg,
      })
    },
    MessageSuccess() {
      this.$swal.fire({
        icon: 'success',
        title: 'Confirmação',
        text: 'Formulário enviado com sucesso!',
      })
    }
  },
  created(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    document.body.style.backgroundColor = "#426B7C"
    document.body.style.backgroundImage = this.$mq !== 'xs' 
                                          && this.$mq !== 'sm' 
                                          && this.$mq !== 'md' ? 
                                            !this.imgWebp ? "url('/img/BG_CONTATO.jpg')" : "url('/img/BG_CONTATO.webp')" : 
                                            !this.imgWebp ? "url('/img/sm/BG_CONTATO.jpg')" : "url('/img/sm/BG_CONTATO.webp')"
  }
}
</script>

<style lang="scss" scoped>
.campo{
  width: 50%;
  display: inline-block;
}
.formulario{
  flex: 1;
  justify-content: flex-start !important;
  text-align: left !important;
  label{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px !important;
    color: #ffffff;
    display: block;
    text-align: left;
    margin-bottom: 6px;
  }
  input[type=tel]{
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
  }
  input{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    height: 45px;
    width: 100%;
    margin-bottom: 8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
  }
  textarea{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    min-height: 180px;
    width: 100%;
    margin-bottom: 8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding: 10px;
    font-size: 18px;
  }
  .titulo{
    margin-bottom: 60px !important;
  }
  .enviar {
    width: 100%;
    background-color: #0a7bac;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 18px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    margin-right: 10px;
    &.disabled{
      background-color: #333333;
      color: #ffffff;
      cursor: not-allowed;
    }
    &:hover {
      background-color: #0098DA;
      &.disabled{
        background-color: #333333;
        color: #ffffff;
        cursor: not-allowed;
      }
    }
  }
  .limpar {
    width: 100%;
    background-color: #f80b2f;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 18px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    &:hover {
      background-color: #F36B80;
    }
  }
}
</style>